import React, { Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import confirmado from "../Images/confirmado.svg";
import whats_b from "../Images/iconos/whats_b.svg";

const Confirmada = props => {
    const HORAS = ['0:00', '0:30', '1:00', '1:30', '2:00', '2:30', '3:00', '3:30', '4:00', '4:30', '5:00', '5:30', '6:00', '6:30', '7:00', '7:30', '8:00', '8:30', '9:00', '9:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30']
    const MESES = [
        "Enero",
        "Febrero", 
        "Marzo", 
        "Abril", 
        "Mayo", 
        "Junio", 
        "Julio", 
        "Agosto", 
        "Septiembre", 
        "Octubre", 
        "Noviembre", 
        "Diciembre"
    ]

    return (
        <section className='container center row'>
            <div className='col s12 m6'>
                <p style={{fontSize:"27px", color:"#E2424E", fontWeight:"bold"}}>¡Tu cita ha sido confirmada!</p>
                {!props.citas ? (
                    <h1>cargando...</h1>
                ) : (
                    <Fragment>
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <div className='sombras' style={{padding:"5px 40px 40px 40px", borderRadius:"15px", maxWidth:"450px"}}>
                                <p style={{fontSize:"23px", color:"#363636"}}>Tu cita:</p>
                                <p style={{margin: "0px"}}>En el consultorio: <b style={{color:"#363636"}}>{props.citas.hospital}</b></p>
                                <p style={{margin: "0px"}}>Fecha: <b style={{color:"#363636"}}>{new Date(props.citas.fecha.seconds * 1000).getDate() + "/" + MESES[new Date(props.citas.fecha.seconds * 1000).getMonth()] + "/" + new Date(props.citas.fecha.seconds * 1000).getFullYear()}</b></p>
                                <p style={{margin: "0px"}}>Hora: <b style={{color:"#363636"}}>{HORAS[props.citas.hora]}</b></p>
                                <p style={{margin: "0px"}}>Paciente: <b style={{color:"#363636"}}>{props.citas.nombre}</b></p>
                                <p style={{margin: "0px"}}>Info adicional: <b style={{color:"#363636"}}>{props.citas.mensaje}</b></p>
                            </div>
                        </div>
                        <p className='text_4' style={{fontWeight:"bold"}}>Te recomendamos conservar estos datos mediante una captura de pantalla</p>
                        <div>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://api.whatsapp.com/send?phone=+525559670113&text=Hola buen dia, me podria ayudar con la sita con la siguiente información:%0APaciente: ${props.citas.nombre}%0AFecha: ${new Date(props.citas.fecha.seconds * 1000).getDate() + "/" + MESES[new Date(props.citas.fecha.seconds * 1000).getMonth()] + "/" + new Date(props.citas.fecha.seconds * 1000).getFullYear()}%0AHora: ${HORAS[props.citas.hora]}%0AHospital: ${props.citas.hospital}`}
                            >
                                <div className='redes_agendado'>
                                    <img src={whats_b} alt="WhatsApp Dra. Mariana Mayorquín Ruiz" />
                                    <p>Contáctanos</p>
                                </div>
                            </a>
                        </div>
                    </Fragment>
                )}
            </div>
            <div className='col s12 m6' style={{marginTop:"30px"}}>
                <img src={confirmado} style={{width:"100%"}} alt="Cita confirmada" />
            </div>
        </section>
    );
};

export default compose(
    firestoreConnect(props => [ 
        { 
            collection: 'citas',
            doc: window.location.pathname.split('/')[2]
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        citas: ordered.citas && ordered.citas[0]
    }))
)(Confirmada);