const firebaseHelper = {
    /**
     * 
     * @param {nombre de la coleccion a eliminar} collection 
     * @param {conexion de firebase} firestore 
     * @param {datos a agregar} data 
     * @param {URL de redireccionamiento} url 
     * @param {conexion con funcion history} history 
     */
    agregar: (collection, firestore, data, url) => {
        firestore.add({ 
            collection: collection 
        }, data).then(res => {
            if (url === "/cita-agendada") {
                window.location.replace(url + "/" + res.id);
            } else {   
                window.location.replace(url);
            }
        });
    },
    /**
     * 
     * @param {conexion de firebase} firestore 
     * @param {fecha a consultar} fecha 
     * @returns data
     */
    consultaHoras: async (firestore, fecha) => {
        console.log({fecha});
        let horas = [];

        function consulta1() {
            return new Promise(async resolve => {
                firestore.collection("citas")
                .where("fecha", "==", fecha)
                .where("estado", "==", true)
                .get()
                .then((querySnapshot) => {
                    if (querySnapshot.empty) {
                        resolve(false);
                    } else {
                        querySnapshot.forEach((doc) => {
                            // doc.data() is never undefined for query doc snapshots
                            horas.push(doc.data().hora);
    
                            resolve(horas);
                        });
                    }
                })
                .catch((error) => {
                    resolve(false);
                    // console.log("Error getting documents: ", error);
                });
            })
        }

        async function guardarState1() {
            const acceso =  consulta1();
            return await acceso;
        }
        
        let resultadoFinal = await guardarState1();

        if (resultadoFinal) {
            return {data:resultadoFinal, success:true};
        } else {
            return {data:[], success:resultadoFinal};

        }
    }
}

export default firebaseHelper;