import React, { Fragment, useState } from 'react'
import { firestoreConnect } from 'react-redux-firebase'

import firebaseHelper from '../Helpers/Firebase'

import location from '../Images/iconos/location.svg'
import whats_b from '../Images/iconos/whats_b.svg'

const Contacto = (props) => {
  const [state, setState] = useState({
    nombre: '',
    telefono: '',
    email: '',
    mensaje: ''
  })

  const leerDato = (e) => {
    e.preventDefault()
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const enviar = (e) => {
    e.preventDefault()
    const { firestore } = props

    firebaseHelper.agregar('mensajes', firestore, state, '/agradecimiento')
  }

  return (
    <Fragment>
      <section className="container">
        <div className="container center" style={{ marginBottom: '50px' }}>
          <p className="titulo_1 linea_3">Contáctanos</p>
          <p>
            ¿Tienes alguna duda? <br />
            Llámanos, escribe un mensaje o correo y te responderemos lo mas
            pronto posible.
          </p>
        </div>
        <div
          style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '50px' }}
        >
          <div className="sombras contenedor_contacto">
            <div
              style={{
                width: '75px',
                height: '75px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#4EB5EA'
              }}
            >
              <img src={location} alt="" style={{ width: '17px' }} />
            </div>
            <p style={{ marginBottom: '0px' }}>Hospital Médica Sur.</p>
            <p>
              <b>Puente de Piedra 150,</b> <br /> Col. Toriello Guerra, <br />
              Alcaldía Tlalpan, 14050
              <br /> Ciudad de México. <br />
              Torre 2, consultorio 425.
              <br />
              <b>Teléfono:</b>{' '}
              <a
                style={{ textDecorationLine: 'underline', color: '#626262' }}
                href="#!"
              >
                55 5335 3694
              </a>{' '}
              <br />
              <a
                style={{ textDecorationLine: 'underline', color: '#626262' }}
                href="#!"
              >
                55 54247200 ext. 4334
              </a>
            </p>
          </div>
          <div className="mapa_contacto">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7531.435802705591!2d-99.16568152329344!3d19.29463012408818!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ce005bf1c00001%3A0x7608aa8d05f72564!2sHospital%20M%C3%A9dica%20Sur!5e0!3m2!1ses-419!2smx!4v1671066957595!5m2!1ses-419!2smx"
              style={{
                width: '100%',
                border: 'none',
                height: '100%',
                borderRadius: '15px'
              }}
              title="Hospital Médica Sur"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>

        <div
          style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '70px' }}
        >
          <div className="sombras contenedor_contacto">
            <div
              style={{
                width: '75px',
                height: '75px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#4EB5EA'
              }}
            >
              <img src={location} alt="" style={{ width: '17px' }} />
            </div>
            <p style={{ marginBottom: '0px' }}>Clínica Médica Polanco.</p>
            <p>
              <b>Edgar Allan Poe 108,</b> <br />
              Col. Polanco, Alcaldia Miguel Hidalgo, <br />
              11550 Ciudad de México. <br />
              <b>Teléfono:</b>{' '}
              <a
                style={{ textDecorationLine: 'underline', color: '#626262' }}
                href="#!"
              >
                55 5281 1607
              </a>{' '}
              <br />
              <a
                style={{ textDecorationLine: 'underline', color: '#626262' }}
                href="#!"
              >
                55 5281 5001
              </a>
            </p>
          </div>
          <div className="mapa_contacto">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.516987542824!2d-99.20453757060771!3d19.433264703756812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d20203213d87bf%3A0x5338133fe1edfcaf!2sClinica%20Medica%20Polanco!5e0!3m2!1ses-419!2smx!4v1671067334897!5m2!1ses-419!2smx"
              style={{
                width: '100%',
                border: 'none',
                height: '100%',
                borderRadius: '15px'
              }}
              title="Clínica Médica Polanco"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>

      <section className="fondo_3" style={{ height: 'auto' }}>
        <form onSubmit={enviar} className="container center">
          <p className="titulo_2 linea_5" style={{ textAlign: 'center' }}>
            ¿Tienes alguna duda?
          </p>
          <a
            className="button_2_1"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '7px',
              width: 'fit-content',
              margin: 'auto'
            }}
            href="https://api.whatsapp.com/send?phone=+525559670113&text=Hola,%20me%20gustaría%20agendar%20una%20cita"
          >
            <img
              id="imagenes"
              src={whats_b}
              style={{ width: '20px' }}
              alt="WhatsApp Dra. Mariana Mayorquín Ruiz"
            />
            Escríbenos
          </a>
          {/* <p className="text_1">
            Escríbenos y nos comunicaremos contigo lo más pronto posible.
          </p>
          <div className="row">
            <div className="col s12 m4">
              <input
                onChange={leerDato}
                type="text"
                name="nombre"
                placeholder="Nombre"
                className="input_form"
                required
              />
            </div>
            <div className="col s12 m4">
              <input
                onChange={leerDato}
                type="tel"
                name="telefono"
                placeholder="Teléfono"
                className="input_form"
                minlength="8"
                maxlength="10"
                pattern="[0-9]{10}"
                required
              />
            </div>
            <div className="col s12 m4">
              <input
                onChange={leerDato}
                type="email"
                name="email"
                placeholder="E-mail"
                className="input_form"
                required
              />
            </div>
            <div className="col s12">
              <textarea
                onChange={leerDato}
                name="mensaje"
                placeholder="Mensaje"
                className="area_form"
                required
              />
            </div>
            <button
              className="button_2"
              type="submit"
              style={{ marginTop: "30px" }}
              href="/servicios"
            >
              ENVIAR
            </button>
          </div> */}
        </form>
      </section>
    </Fragment>
  )
}

export default firestoreConnect()(Contacto)
