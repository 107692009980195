import React, { useEffect, useState } from 'react';
import "./Galeria.css";

// import galeria from "../../Images/galeria.png";
import img1 from "../../Images/galeria/1.png";
import img2 from "../../Images/galeria/2.png";
import img3 from "../../Images/galeria/3.png";
import img4 from "../../Images/galeria/4.png";
import img6 from "../../Images/galeria/6.png";
import img7 from "../../Images/galeria/7.png";
import img8 from "../../Images/galeria/8.png";
import img9 from "../../Images/galeria/9.png";
import img10 from "../../Images/galeria/10.png";
import img11 from "../../Images/galeria/11.png";
import img12 from "../../Images/galeria/12.png";
import img13 from "../../Images/galeria/13.png";
import img14 from "../../Images/galeria/14.png";

import vid1 from "../../Images/galeria/video/1.mp4";
import vid2 from "../../Images/galeria/video/2.mp4";
import vid3 from "../../Images/galeria/video/3.mp4";
import vid4 from "../../Images/galeria/video/4.mp4";


const Galeria = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [translateVal, setTranslateVal] = useState(0);
    const [defaultVal, setDefaultVal] = useState(0);
    const imagenes = [
        {nombre: "Acercamiento a córnea", imagen: img1, tipo: 0},
        {nombre: "Nevo de iris", imagen: img2, tipo: 0},
        {nombre: "Catarata con pupila dilatada", imagen: img3, tipo: 0},
        {nombre: "Catarata", imagen: img4, tipo: 0},
        {nombre: "Microscopio especular", imagen: img6, tipo: 0},
        {nombre: "Microscopía especular", imagen: img7, tipo: 0},
        {nombre: "Microscopio especular", imagen: img8, tipo: 0},
        {nombre: "OPD", imagen: img9, tipo: 0},
        {nombre: "Resultados de Microscopía especular", imagen: img10, tipo: 0},
        {nombre: "Resultados de OPD", imagen: img11, tipo: 0},
        {nombre: "Técnica de ultrasonido ocular", imagen: img12, tipo: 0},
        {nombre: "UBM", imagen: img13, tipo: 0},
        {nombre: "Ultrasonido ocular", imagen: img14, tipo: 0},
        {nombre: "Bloqueo inverso sólo en miosis", imagen: vid1, tipo: 1},
        {nombre: "UBM", imagen: vid2, tipo: 1},
        {nombre: "Ultrasonido en tumor intraocular", imagen: vid3, tipo: 1},
        {nombre: "Ultrasonido ocular", imagen: vid4, tipo: 1},
    ]

    useEffect(() => {
        const container = document.querySelector('.avatars');
        const avatars = document.querySelectorAll('.avatars-container .avatar-item');
        setCurrentIndex(Math.floor(avatars.length / 2));

        for (let i = 0; i < avatars.length; i++) {
            if (i === Math.floor(avatars.length / 2)) {
                avatars[i].classList.add('current');
            }
        }

        if (avatars.length % 2 === 0) {
            setDefaultVal(340);
            setTranslateVal(-340);
            container.style.transform = `translateX(${-340}px)`
        }

        if (window.innerWidth > 1664) {
            if (avatars.length % 2 === 0) {
                setDefaultVal(400);
                setTranslateVal(-400);
                container.style.transform = `translateX(${-340}px)`
            }
        } else if (window.innerWidth < 551) {
            if (avatars.length % 2 === 0) {
                setDefaultVal(200);
                setTranslateVal(-175);
                container.style.transform = `translateX(${-175}px)`
            }
        }
        // window.addEventListener("resize", function(){
        // });

    }, []);

    const siguiente = () => {
        const container = document.querySelector('.avatars');
        const avatars = document.querySelectorAll('.avatars-container .avatar-item');

        let val = (avatars.length - 1 - Math.floor(avatars.length / 2)) * 630;
        if (window.innerWidth > 1664) {
            val = (avatars.length - 1 - Math.floor(avatars.length / 2)) * 750;
        } else if (window.innerWidth < 551) {
            val = (avatars.length - 1 - Math.floor(avatars.length / 2)) * 350;
        }

        let mover = translateVal;

        if (currentIndex + 1 >= avatars.length) {
            avatars[currentIndex].classList.remove('current');
            avatars[0].classList.add('current');
            setCurrentIndex(0);
            mover = val + defaultVal
            setTranslateVal(mover);
            container.style.transform = `translateX(${mover}px)`
            return
        }

        avatars[currentIndex].classList.remove('current')
        avatars[currentIndex + 1].classList.add('current');
        let siguiente = currentIndex + 1;
        setCurrentIndex(siguiente);

        if (window.innerWidth > 1664) {
            mover = mover - 750;
        } else if (window.innerWidth < 551) {
            mover = mover - 350;
        } else {
            mover = mover - 630;
        }

        setTranslateVal(mover);
        container.style.transform = `translateX(${mover}px)`;
    }

    const anterior = () => {
        const container = document.querySelector('.avatars');
        const avatars = document.querySelectorAll('.avatars-container .avatar-item');

        let val = (avatars.length - 1 - Math.floor(avatars.length / 2)) * 630;
        if (window.innerWidth > 1664) {
            val = (avatars.length - 1 - Math.floor(avatars.length / 2)) * 750;
        } else if (window.innerWidth < 551) {
            val = (avatars.length - 1 - Math.floor(avatars.length / 2)) * 350;
        }

        let mover = translateVal;

        if (currentIndex - 1 < 0) {
            avatars[currentIndex].classList.remove('current');
            avatars[avatars.length - 1].classList.add('current');
            setCurrentIndex(avatars.length - 1);
            mover = (val - defaultVal) * -1;
            setTranslateVal(mover);
            container.style.transform = `translateX(${mover}px)`
        } else {
            avatars[currentIndex].classList.remove('current')
            avatars[currentIndex - 1].classList.add('current')
            let anterior = currentIndex - 1;
            setCurrentIndex(anterior);

            if (window.innerWidth > 1664) {
                mover = mover + 750;
            } else if (window.innerWidth < 551) {
                mover = mover + 350;
            } else {
                mover = mover + 630;
            }

            setTranslateVal(mover);
            container.style.transform = `translateX(${mover}px)`
        }
    }

    return (
        <div class="choose-avatar">
            <div class="avatars-container">
                <span onClick={anterior} class="left_button"></span>
                <div class="avatars">
                    {imagenes.map(imagen => (
                        <div class="avatar-item" style={{flexDirection:"column"}}>
                            {imagen.tipo === 0 ? (
                                <img src={imagen.imagen} alt={imagen.nombre} style={{width:"90%", borderRadius:"15px"}} />
                            ) : (
                                
                                <video class="responsive-video" controls style={{maxWidth:"90%", borderRadius:"15px"}}>
                                    <source src={imagen.imagen} alt={imagen.nombre} type="video/mp4" />
                                </video>
                            )}
                            <p>{imagen.nombre}</p>
                        </div>
                    ))}
                </div>
                <span onClick={siguiente} class="right_button"></span>
            </div>
        </div>
    );
};

export default Galeria;