import React from 'react'

import img6 from '../../Images/servicios/internas/6.png'
import img6_1 from '../../Images/servicios/internas/6_1.png'

const PaquimetriaUltrasonica = () => {
  return (
    <section style={{ marginBottom: '80px' }} className="container text_1">
      <p className="titulo_1 linea_3">Paquimetría ultrasónica</p>
      <img src={img6} alt="Paquimetría ultrasónica" style={{ width: '100%' }} />
      <p className="titulo_3">Descripción</p>
      <p>
        Tiene el propósito para medir el grosor de la córnea, utilizando una
        sonda ultrasónica.
      </p>
      <p>
        Este examen es de vital importancia a la hora de determinar la cantidad
        de vicio de refracción (miopía, astigmatismo, hipermetropía) que puede
        ser tratado ya que un grosor corneal bajo limita la cuantía de vicio de
        refracción a tratar.
      </p>
      <p>
        También se indica en el estudio de la correlación entre presión
        intraocular y grosor corneal en pacientes con glaucoma o sospechosos de
        tenerlo.
      </p>
      <img
        src={img6_1}
        className="imagen_servicios"
        alt="Paquimetría ultrasónica"
      />
      <p style={{ fontWeight: 'bold' }}>¿Cuál es el procedimiento?</p>
      <p>
        Bajo anestesia tópica (gotas), se realizan leves toques en la córnea
        para realizar una correcta medición.
      </p>

      <div className="center" style={{ marginTop: '45px' }}>
        <a
          className="button_3"
          target="_blanck"
          href="https://api.whatsapp.com/send?phone=+525559670113&text=Hola,%20me%20gustaría%20agendar%20una%20cita"
        >
          Agenda tu cita
        </a>
      </div>
    </section>
  )
}

export default PaquimetriaUltrasonica
