import React, { useState } from 'react';

const dataContext = React.createContext();

const DataContext = ({ children }) => {
    const [state, setState] = useState({});

    return ( 
        <dataContext.Provider value={{state, setState}}>
            {children}
        </dataContext.Provider>
    );
}
 
export {DataContext, dataContext};