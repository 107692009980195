import React from 'react'

import footer from '../Images/footer.png'

import location from '../Images/iconos/location.svg'
import phone from '../Images/iconos/phone.svg'
import whats from '../Images/iconos/whats.svg'
import insta from '../Images/iconos/insta.svg'
import face from '../Images/iconos/face.svg'
import mail from '../Images/iconos/mail.svg'

const Footer = () => {
  return (
    <footer className="page-footer">
      <div className="contenedor" style={{ marginTop: '20px' }}>
        <div className="row">
          <div
            className="col l3 s12 desktop_off"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <a href="/">
              <img
                src={footer}
                alt="Imagen Oftalmógica"
                style={{ width: '100%', maxWidth: '217px' }}
              />
            </a>
          </div>

          <div className="col l4 s12 center_footer">
            <div
              style={{
                border: 'solid 2px #ffffff',
                width: '75px',
                height: '75px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <img src={location} alt="" style={{ width: '17px' }} />
            </div>
            <div
              style={{ paddingLeft: '10px', width: 'calc(100% - 75px)' }}
              className="text_footer"
            >
              <p className="margin_1 text_1" style={{ fontWeight: 'bold' }}>
                Hospital Médica Sur.
              </p>
              <p className="margin_1">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://goo.gl/maps/YhkWCvB9s16zonsV6"
                >
                  Torre 2, consultorio 425 <br />
                  Puente de Piedra 150, Toriello Guerra, <br />
                  Tlalpan, 14050 Ciudad de México, CDMX.
                </a>
              </p>
              <p
                style={{ textDecorationLine: 'underline', fontWeight: 'bold' }}
                className="margin_1"
              >
                <a href="tel:+525553353694" target="_blank" rel="noreferrer">
                  55 5335 3694
                </a>{' '}
                /
                <a href="tel:+525554247200" target="_blank" rel="noreferrer">
                  55 5424 7200
                </a>{' '}
                ext. 4334
              </p>
            </div>
          </div>

          <div className="col l4 s12 center_footer">
            <div
              style={{
                border: 'solid 2px #ffffff',
                width: '75px',
                height: '75px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <img src={phone} alt="" style={{ width: '17px' }} />
            </div>
            <div
              style={{
                paddingLeft: '10px',
                display: 'flex',
                alignItems: 'center'
              }}
              className="text_footer"
            >
              <p
                style={{ textDecorationLine: 'underline', fontWeight: 'bold' }}
                className="margin_1"
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://api.whatsapp.com/send?phone=+525559670113&text=Hola,%20me%20gustaría%20agendar%20una%20cita"
                >
                  Whatsapp: +52 55596 70113
                </a>
              </p>
            </div>
          </div>

          <div className="col l4 s12 center_footer">
            <div
              style={{
                border: 'solid 2px #ffffff',
                width: '75px',
                height: '75px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <img src={location} alt="" style={{ width: '17px' }} />
            </div>
            <div
              style={{ paddingLeft: '10px', width: 'calc(100% - 75px)' }}
              className="text_footer"
            >
              <p className="margin_1 text_1" style={{ fontWeight: 'bold' }}>
                Clínica Médica Polanco.
              </p>
              <p className="margin_1">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://goo.gl/maps/EcDuwCMxHUSuYExy8"
                >
                  Edgar Allan Poe 108, Col. Polanco, Alcaldia <br />
                  Miguel Hidalgo, 11550 Ciudad de México, CDMX.
                </a>
              </p>
              <p
                style={{ textDecorationLine: 'underline', fontWeight: 'bold' }}
                className="margin_1"
              >
                <a href="tel:+525552811607" target="_blank" rel="noreferrer">
                  55 5281 1607
                </a>{' '}
                /
                <a href="tel:+525552815001" target="_blank" rel="noreferrer">
                  55 5281 5001
                </a>
              </p>
            </div>
          </div>
        </div>

        <div class="divider mobil_off" />

        <div className="row" style={{ marginBottom: '0px' }}>
          <div
            className="col l3 s12 mobil_off"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              src={footer}
              alt="Imagen Oftalmógica"
              style={{ width: '100%', maxWidth: '217px' }}
            />
          </div>
          <div
            className="col l9 s12 center_servicios_footer"
            style={{ marginTop: '20px' }}
          >
            <div className="col l4 s12">
              <h5
                className="white-text text_2 linea"
                style={{ fontWeight: 'bold' }}
              >
                Servicios
              </h5>
              <ul className="footer_list">
                <li>
                  <a
                    className="text_3 text-lighten-3"
                    href="/ultrasonido-ocular"
                  >
                    Ultrasonido ocular
                  </a>
                </li>
                <li>
                  <a
                    className="text_3 text-lighten-3"
                    href="/ultrabiomicroscopia"
                  >
                    Ultrabiomicroscopía
                  </a>
                </li>
                <li>
                  <a
                    className="text_3 text-lighten-3"
                    href="/microscopia-especular"
                  >
                    Microscopía especular
                  </a>
                </li>
              </ul>
            </div>
            <div className="col l4 s12">
              <h5
                className="white-text text_2 linea"
                style={{ fontWeight: 'bold' }}
              >
                Navegación
              </h5>
              <ul className="footer_list">
                <li>
                  <a className="text_3 text-lighten-3" href="/dra-marina">
                    Dra. Mariana
                  </a>
                </li>
                <li>
                  <a
                    className="text_3 text-lighten-3"
                    href="/aviso-de-privacidad"
                  >
                    Aviso de privacidad
                  </a>
                </li>
                <li>
                  <a
                    className="text_3 text-lighten-3"
                    href="/terminos-y-condiciones"
                  >
                    Términos y condiciones
                  </a>
                </li>
              </ul>
            </div>
            <div className="col l4 s12" style={{ height: '200px' }}>
              <h5
                className="white-text text_2 linea"
                style={{ fontWeight: 'bold' }}
              >
                Contacto
              </h5>
              <div style={{ display: 'flex', marginTop: '30px' }}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://api.whatsapp.com/send?phone=+525559670113&text=Hola,%20me%20gustaría%20agendar%20una%20cita"
                >
                  <div
                    style={{
                      background: '#B4D9F5',
                      borderRadius: '50%',
                      width: '34px',
                      height: '34px',
                      display: 'flex',
                      justifyContent: 'center',
                      marginRight: '10px'
                    }}
                  >
                    <img src={whats} alt="" style={{ width: '20px' }} />
                  </div>
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/imagenoftalmologica"
                >
                  <div
                    style={{
                      background: '#B4D9F5',
                      borderRadius: '50%',
                      width: '34px',
                      height: '34px',
                      display: 'flex',
                      justifyContent: 'center',
                      marginRight: '10px'
                    }}
                  >
                    <img src={insta} alt="" style={{ width: '20px' }} />
                  </div>
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/people/Dra-Mariana-Mayorqu%C3%ADn-Ruiz/100089109151488"
                >
                  <div
                    style={{
                      background: '#B4D9F5',
                      borderRadius: '50%',
                      width: '34px',
                      height: '34px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '10px'
                    }}
                  >
                    <img src={face} alt="" style={{ height: '20px' }} />
                  </div>
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="mailto:contacto@imagenoftalmologica.com?subject=Solicitud%20de%20informaci%F3n&body=Buen%20d%EDa%2C%20me%20podr%EDa%20brindar%20informaci%F3n%20de%20sus%20servicios."
                >
                  <div
                    style={{
                      background: '#B4D9F5',
                      borderRadius: '50%',
                      width: '34px',
                      height: '34px',
                      display: 'flex',
                      justifyContent: 'center',
                      marginRight: '10px'
                    }}
                  >
                    <img src={mail} alt="" style={{ width: '20px' }} />
                  </div>
                </a>
              </div>
              <a
                className="button_footer"
                target="_blanck"
                href="https://api.whatsapp.com/send?phone=+525559670113&text=Hola,%20me%20gustaría%20agendar%20una%20cita"
              >
                Agenda una cita
              </a>
            </div>
          </div>
        </div>

        <div class="divider mobil_off" />
      </div>
      <div className="footer-copyright">
        <div style={{ fontSize: '11px' }} className="container center">
          <p>© {new Date().getFullYear()} Imagenoftalmologica</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
