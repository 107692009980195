import React from 'react'

import agradecimiento from '../Images/agradecimiento.svg'
import whats from '../Images/iconos/whats.svg'
import insta from '../Images/iconos/insta.svg'
import face from '../Images/iconos/face.svg'
import mail from '../Images/iconos/mail.svg'

const Agradecimiento = () => {
  return (
    <section
      className="container row"
      style={{ marginTop: '30px', marginBottom: '100px' }}
    >
      <div className="col s12 m6">
        <p>
          <b style={{ fontSize: '27px', color: '#E2424E' }}>
            ¡Formulario enviado!
          </b>
        </p>
        <p>
          <b>
            Gracias por enviarnos tu mensaje, en breve nos pondremos en contacto
            contigo.
          </b>
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: '25px',
            marginBottom: '50px'
          }}
        >
          <a className="button_3" href="/">
            Volver al inicio
          </a>
          <div style={{ display: 'flex', marginTop: '30px' }}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://api.whatsapp.com/send?phone=+525559670113&text=Hola,%20me%20gustaría%20agendar%20una%20cita"
            >
              <div
                style={{
                  background: '#B4D9F5',
                  borderRadius: '50%',
                  width: '34px',
                  height: '34px',
                  display: 'flex',
                  justifyContent: 'center',
                  marginRight: '20px'
                }}
              >
                <img src={whats} alt="" style={{ width: '20px' }} />
              </div>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/imagenoftalmologica"
            >
              <div
                style={{
                  background: '#B4D9F5',
                  borderRadius: '50%',
                  width: '34px',
                  height: '34px',
                  display: 'flex',
                  justifyContent: 'center',
                  marginRight: '20px'
                }}
              >
                <img src={insta} alt="" style={{ width: '20px' }} />
              </div>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/people/Dra-Mariana-Mayorqu%C3%ADn-Ruiz/100089109151488"
            >
              <div
                style={{
                  background: '#B4D9F5',
                  borderRadius: '50%',
                  width: '34px',
                  height: '34px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '20px'
                }}
              >
                <img src={face} alt="" style={{ height: '20px' }} />
              </div>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:contacto@imagenoftalmologica.com?subject=Solicitud%20de%20informaci%F3n&body=Buen%20d%EDa%2C%20me%20podr%EDa%20brindar%20informaci%F3n%20de%20sus%20servicios."
            >
              <div
                style={{
                  background: '#B4D9F5',
                  borderRadius: '50%',
                  width: '34px',
                  height: '34px',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <img src={mail} alt="" style={{ width: '20px' }} />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="col s12 m6">
        <img
          src={agradecimiento}
          style={{ width: '100%' }}
          alt="Cita confirmada"
        />
      </div>
    </section>
  )
}

export default Agradecimiento
