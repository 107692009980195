import React from 'react'

import img4 from '../../Images/servicios/internas/4.png'
import img4_1 from '../../Images/servicios/internas/4_1.png'

const TopografiaCorneal = () => {
  return (
    <section style={{ marginBottom: '80px' }} className="container text_1">
      <p className="titulo_1 linea_3">OPD</p>
      <img
        src={img4}
        alt="OPD (Topografia Corneal)"
        style={{ width: '100%' }}
      />
      <p className="titulo_3">Descripción</p>
      <p>
        El examen OPD proporciona información sobre la forma de la córnea,
        tamaño pupilar y defecto refractivo.
      </p>
      <p>
        El especialista puede solicitar uno o varios reportes de este equipo y
        puede requerir dilatación pupilar para evaluar aspectos refractivos de
        su ojo.
      </p>
      <p>
        Este examen es usualmente solicitado para: <br />
        - Diagnóstico y seguimiento de los defectos refractivos (miopía,
        hipermetropía y astigmatismo). <br />
        - Control preoperatorio y post operatorio de Cirugía refractiva,
        catarata o de implante de anillos intraestromales. <br />
        - Diagnóstico y seguimiento del queratocono y demás alteraciones
        corneales. <br />- Evaluación y análisis de Lente intraocular.
      </p>
      <img
        src={img4_1}
        className="imagen_servicios"
        alt="OPD (Topografia Corneal)"
      />
      <p style={{ fontWeight: 'bold' }}>¿Cuál es el procedimiento?</p>
      <p>
        Si usas lentes de contacto duros o gas permeables debes suspender su uso
        con mínimo <b>8 días de anterioridad,</b>
        si son lentes blandos el periodo de suspensión debe ser de{' '}
        <b>5 días.</b>
      </p>
      <p>
        Si usa gotas lubricantes o lagrimas artificiales, tráigalas al examen.
      </p>
      <p>
        El exámen tiene duración aproximada de 5 a 10 minutos por ojo. Solo
        deberás mirar hacia donde se te indica mientras se toman las imágenes.
      </p>

      <div className="center" style={{ marginTop: '45px' }}>
        <a
          className="button_3"
          target="_blanck"
          href="https://api.whatsapp.com/send?phone=+525559670113&text=Hola,%20me%20gustaría%20agendar%20una%20cita"
        >
          Agenda tu cita
        </a>
      </div>
    </section>
  )
}

export default TopografiaCorneal
