import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Router from './Routes/Router';

import { DataContext } from './Context/DataContext';

import { Provider } from 'react-redux'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore' // <- needed if using firestore
// import 'firebase/functions' // <- needed if using httpsCallable

import { createStore, combineReducers } from 'redux'
import {
  ReactReduxFirebaseProvider,
  firebaseReducer
} from 'react-redux-firebase'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore' // <- needed if using firestore

const fbConfig = {
  apiKey: "AIzaSyBcDWPwl4yrNlReC0zXOYixArOqn3YDvJE",
  authDomain: "imagen-oftalmologica.firebaseapp.com",
  projectId: "imagen-oftalmologica",
  storageBucket: "imagen-oftalmologica.appspot.com",
  messagingSenderId: "1051798401498",
  appId: "1:1051798401498:web:ea1685f97b19b171a510e3",
  measurementId: "G-S99Z8L9TJ6"
}

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

// Initialize firebase instance
firebase.initializeApp(fbConfig)

// Initialize other services on firebase instance
firebase.firestore() // <- needed if using firestore
// firebase.functions() // <- needed if using httpsCallable

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer // <- needed if using firestore
})

// Create store with reducers and initial state
const initialState = {}
const store = createStore(rootReducer, initialState)

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
}

function App() {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <DataContext>
          <Router />
        </DataContext>
      </ReactReduxFirebaseProvider>
    </Provider>
  )
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
