import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

// *** componentes ***  //
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Redes from '../Components/Redes'

// *** vistas ***  //
import Index from '../Views/Index'
import Nosotros from '../Views/Nosotros/Nosotros'
import Galeria from '../Views/Galeria'
import Contacto from '../Views/Contacto'
import Agenda from '../Views/Agenda'
import Mensaje from '../Views/Mensaje'
import Servicios from '../Views/Servicios'
// servicios
import UltrasonidoOcular from '../Views/AllSErvices/UltrasonidoOcular'
import Ultrabiomicroscopia from '../Views/AllSErvices/Ultrabiomicroscopia'
import MicroscopiaEspecular from '../Views/AllSErvices/MicroscopiaEspecular'
import TopografiaCorneal from '../Views/AllSErvices/TopografiaCorneal'
import CalculoIntraocular from '../Views/AllSErvices/CalculoIntraocular'
import PaquimetriaUltrasonica from '../Views/AllSErvices/PaquimetriaUltrasonica'
import Terminos from '../Views/Terminos'
import Aviso from '../Views/Aviso'
import Error from '../Views/Error'
import Confirmada from '../Views/Confirmada'
import Agradecimiento from '../Views/Agradecimiento'

const Routers = () => (
  <BrowserRouter>
    <Header />
    <Redes />

    <Routes>
      {/* vistas */}
      <Route exact path="/" element={<Index />} />
      <Route path="/dra-marina" element={<Nosotros />} />
      <Route path="/galeria" element={<Galeria />} />
      <Route path="/contactanos" element={<Contacto />} />
      {/* <Route path="/agenda-cita" element={<Agenda />} /> */}
      <Route path="/envio-exitoso" element={<Mensaje />} />
      <Route path="/servicios" element={<Servicios />} />
      {/* servicios */}
      <Route path="/ultrasonido-ocular" element={<UltrasonidoOcular />} />
      <Route path="/ultrabiomicroscopia" element={<Ultrabiomicroscopia />} />
      <Route path="/microscopia-especular" element={<MicroscopiaEspecular />} />
      <Route path="/topografia-corneal" element={<TopografiaCorneal />} />
      <Route
        path="/calculo-de-lente-intraocular"
        element={<CalculoIntraocular />}
      />
      <Route
        path="/paquimetria-ultrasonica"
        element={<PaquimetriaUltrasonica />}
      />
      <Route path="/aviso-de-privacidad" element={<Aviso />} />
      <Route path="/terminos-y-condiciones" element={<Terminos />} />
      <Route path="/cita-agendada/:id" element={<Confirmada />} />
      <Route path="/agradecimiento" element={<Agradecimiento />} />
      <Route path="*" element={<Error />} />
    </Routes>

    <Footer />
  </BrowserRouter>
)

export default Routers
