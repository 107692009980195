import React from 'react';

import Galeria from '../Components/Galeria/Galeria';

const GaleriaComponent = () => {
    return (
        <section style={{textAlign:"center", marginBottom:"50px"}}>
            <p className='titulo_2 linea_4'>Galería</p> 
            <Galeria />
            <a className='button_1' href="/dra-marina">Ver más</a>
        </section>
    );
};

export default GaleriaComponent;