import React from 'react'

import img1 from '../../Images/servicios/internas/1.png'
import img1_1 from '../../Images/servicios/internas/1_1.png'

const UltrasonidoOcular = () => {
  return (
    <section style={{ marginBottom: '80px' }} className="container text_1">
      <p className="titulo_1 linea_3">Ultrasonido ocular</p>
      <img src={img1} alt="Ultrasonido ocular" style={{ width: '100%' }} />
      <p className="titulo_3">Descripción</p>
      <p>
        ¿Conoces el ultrasonido que se realiza, por ejemplo a una embarazada
        para ver el estado de su bebé? El ultrasonido ocular tiene el mismo
        principio, con características especiales diseñadas para el ojo.
      </p>
      <p>
        El ultrasonido ocular/orbitario nos permite conocer el estado de tus
        ojos cuando hay algo que le impida revisarlos a través de la lámpara de
        hendidura; por ejemplo: una catarata, hemorragia intraocular u
        opacidades corneales.
      </p>
      <p>
        Hay condiciones en las que, aún observando el ojo con la lámpara de
        hendidura, el ultrasonido ayuda a guiar algunos diagnósticos, como es el
        caso de los tumores intraoculares.
      </p>
      <img src={img1_1} className="imagen_servicios" alt="Ultrasonido ocular" />
      <p style={{ fontWeight: 'bold' }}>¿Cuál es el procedimiento?</p>
      <p>
        El estudio se realiza en ocasiones a través de los párpados, aunque en
        la mayoría de los casos se hace en contacto directo con el ojo,
        previamente anestesiado, utilizando un gel como medio de acoplamento
        entre el ojo y el transductor.
      </p>
      <p>
        El estudio incluye una interpretación de las imágenes obtenidas y un
        reporte para ti y tu especialista.
      </p>

      <div className="center" style={{ marginTop: '45px' }}>
        <a
          className="button_3"
          target="_blanck"
          href="https://api.whatsapp.com/send?phone=+525559670113&text=Hola,%20me%20gustaría%20agendar%20una%20cita"
        >
          Agenda tu cita
        </a>
      </div>
    </section>
  )
}

export default UltrasonidoOcular
