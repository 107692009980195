import React, { Fragment, useEffect } from 'react'
import M from 'materialize-css'

import header from '../Images/header.png'
import menu from '../Images/iconos/menu.svg'

const Header = () => {
  useEffect(() => {
    var elems = document.querySelectorAll('.sidenav')
    M.Sidenav.init(elems)
  }, [])

  return (
    <Fragment>
      <nav>
        <div className="nav-wrapper">
          <a href="/" className="brand-logo">
            <img
              src={header}
              style={{ height: '100%' }}
              alt="Imagen Oftalmológica"
              className="logo_header"
            />
          </a>
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            className="desktop_off right burger"
          >
            <button
              style={{ marginRight: '0' }}
              data-target="mobile-demo"
              className="sidenav-trigger"
            >
              <img src={menu} alt="" />
            </button>
          </div>
          <ul className="right hide-on-med-and-down">
            <li>
              <a href="/">Inicio</a>
            </li>
            <li>
              <a href="/dra-marina">Dra. Mariana</a>
            </li>
            <li>
              <a href="/servicios">Servicios</a>
            </li>
            <li>
              <a href="/galeria">Galeria</a>
            </li>
            <li>
              <a href="/contactanos">Contacto</a>
            </li>
            <li>
              <a
                target="_blanck"
                className="button_header"
                href="https://api.whatsapp.com/send?phone=+525559670113&text=Hola,%20me%20gustaría%20agendar%20una%20cita"
              >
                Agenda una cita
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <ul className="sidenav" id="mobile-demo">
        <li>
          <a href="/">Inicio</a>
        </li>
        <li>
          <a href="/dra-marina">Dra. Mariana</a>
        </li>
        <li>
          <a href="/servicios">Servicios</a>
        </li>
        <li>
          <a href="/galeria">Galeria</a>
        </li>
        <li>
          <a href="/contactanos">Contacto</a>
        </li>
        <li>
          <a
            target="_blanck"
            className="button_header"
            href="https://api.whatsapp.com/send?phone=+525559670113&text=Hola,%20me%20gustaría%20agendar%20una%20cita"
          >
            Agenda una cita
          </a>
        </li>
      </ul>
    </Fragment>
  )
}

export default Header
