import React, { useState, useContext, useEffect, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
} from "firebase/storage";

import Swal from "sweetalert2";

import { dataContext } from "../Context/DataContext";
import firebaseHelper from "../Helpers/Firebase";

import Calendario from "../Components/Calendario/Calendario";

const Agenda = (props) => {
    const HORAS = [
        "0:00",
        "0:30",
        "1:00",
        "1:30",
        "2:00",
        "2:30",
        "3:00",
        "3:30",
        "4:00",
        "4:30",
        "5:00",
        "5:30",
        "6:00",
        "6:30",
        "7:00",
        "7:30",
        "8:00",
        "8:30",
        "9:00",
        "9:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
        "23:00",
        "23:30",
    ];
    const stateContext = useContext(dataContext);
    const [state, setState] = useState({
        hospital: "",
        nombre: "",
        telefono: "",
        email: "",
        primera_cita: "",
        ojo: "",
        mensaje: "",
    });
    const [fechaState, setFechaState] = useState("");
    const [horasState, setHoraState] = useState("");
    const [horasTarget, setHoraTarget] = useState([]);
    const [servicios, setServicios] = useState([]);
    const [archivo, setArchivo] = useState("");
    const [flag, setFlag] = useState(true);

    useEffect(() => {
        const slider = document.querySelector(".horizontal_horas");
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener("mousedown", (e) => {
            isDown = true;
            slider.classList.add("active");
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener("mouseleave", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mouseup", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mousemove", (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 2; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        });
    }, []);

    const leerDato = (e) => {
        // e.preventDefault();
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    };

    const leerServicio = (e) => {
        const copiaServicios = [...servicios];

        let indexServicio = copiaServicios.indexOf(e.target.value);

        if (indexServicio >= 0) {
            copiaServicios.splice(indexServicio, 1);
        } else {
            copiaServicios.push(e.target.value);
        }

        setServicios(copiaServicios);
    };

    const enviar = (e) => {
        e.preventDefault();

        if (horasState === "" || fechaState === "" || servicios.length <= 0) {
            Swal.fire({
                title: "Error",
                text: "Faltan datos por llenar",
                confirmButtonColor: "#2DCD22",
                confirmButtonText: "Continuar",
                icon: "error",
            });
        } else {
            setFlag(false);
            if (archivo !== "") {
                subirImagen();
            } else {
                subirDB("");
            }
        }
    };

    const subirImagen = () => {
        const storage = getStorage();
        const storageRef = ref(
            storage,
            `/consultas/${
                state.nombre
            }-${fechaState.getDate()}-${fechaState.getMonth()}-${fechaState.getFullYear()}-${horasState}`
        );

        const uploadTask = uploadBytesResumable(storageRef, archivo);

        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 800;
            },
            (error) => {
                // Handle unsuccessful uploads
                Swal.fire("Error", "Error al subir el archivo", "error");
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    subirDB(downloadURL);
                });
            }
        );
    };

    const subirDB = (url) => {
        const { firestore } = props;

        let nuevo = {
            ...state,
            hora: horasState,
            fecha: fechaState,
            estado: true,
            servicios,
            url,
        };

        setFlag(true);
        firebaseHelper.agregar("citas", firestore, nuevo, "/cita-agendada");
    };

    const horas = async (fecha) => {
        const { firestore } = props;
        let res = await firebaseHelper.consultaHoras(firestore, fecha);

        let horarioProps = [],
            index,
            componente = [];
        if (state.hospital === "Hospital Médica Sur") {
            index = 2;
        } else {
            index = 1;
        }

        switch (fecha.getDay()) {
            case 0:
                // Domingo
                horarioProps = props.horarios[index].horario_domingo;
                break;
            case 1:
                // Lunes
                horarioProps = props.horarios[index].horario_lunes;
                break;
            case 2:
                // Martes
                horarioProps = props.horarios[index].horario_martes;
                break;
            case 3:
                // Miercoles
                horarioProps = props.horarios[index].horario_miercoles;
                break;
            case 4:
                // Jueves
                horarioProps = props.horarios[index].horario_jueves;
                break;
            case 5:
                // Viernes
                horarioProps = props.horarios[index].horario_viernes;
                break;
            case 6:
                // Sabado
                horarioProps = props.horarios[index].horario_sabado;
                break;
            default:
                horarioProps = [];
                break;
        }

        horarioProps.map((hora) => {
            for (
                let index = parseInt(hora.inicio);
                index <= parseInt(hora.fin);
                index++
            ) {
                if (!res.data.includes(index) || !res.success) {
                    componente.push(
                        <p className="hora_select">
                            <label className="input_horas">
                                <input
                                    onChange={() => {
                                        setHoraState(index);
                                    }}
                                    name="horas"
                                    type="radio"
                                />
                                <div
                                    className="hora_div"
                                    title={HORAS[index]}
                                />
                            </label>
                        </p>
                    );
                }
            }
            return true;
        });

        setHoraTarget(componente);
        setFechaState(fecha);
    };

    return (
        <Fragment>
            {!flag && (
                <div
                    style={{
                        position: "fixed",
                        top: "0",
                        width: "100%",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: "9999",
                        background: "#0000006e",
                    }}
                >
                    <div class="preloader-wrapper big active">
                        <div class="spinner-layer spinner-blue-only">
                            <div class="circle-clipper left">
                                <div class="circle"></div>
                            </div>
                            <div class="gap-patch">
                                <div class="circle"></div>
                            </div>
                            <div class="circle-clipper right">
                                <div class="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <section className="container">
                <p className="titulo_1 linea_3">Agenda tu cita</p>
                <form className="row" onSubmit={enviar}>
                    <div className="col s12 l7">
                        <p className="titulo_3">
                            ¿En qué consultorio quieres agendar la cita?
                        </p>
                        <p>
                            <label>
                                <input
                                    onChange={leerDato}
                                    name="hospital"
                                    value={"Hospital Médica Sur"}
                                    type="radio"
                                    required
                                />
                                <span className="text_2">
                                    Hospital Médica Sur
                                </span>
                            </label>
                        </p>
                        <p>
                            <label>
                                <input
                                    onChange={leerDato}
                                    name="hospital"
                                    value={"Clínica Médica Polanco"}
                                    type="radio"
                                    required
                                />
                                <span className="text_2">
                                    Clínica Médica Polanco
                                </span>
                            </label>
                        </p>

                        <div class="container divider divider_1" />

                        <p className="titulo_3">Fechas disponibles</p>
                        {state.hospital === "" && (
                            <Calendario bloqueado={true} />
                        )}
                        {state.hospital === "Hospital Médica Sur" && (
                            <Calendario
                                diasBloqueados={props.horarios[0].diasMedicaSur}
                                semanaBloquedas={props.horarios[2].dias_semana}
                                onSelect={(fecha) => {
                                    horas(fecha);
                                }}
                            />
                        )}
                        {state.hospital === "Clínica Médica Polanco" && (
                            <Calendario
                                diasBloqueados={
                                    props.horarios[0].diasMedicaPolanco
                                }
                                semanaBloquedas={props.horarios[1].dias_semana}
                                onSelect={(fecha) => {
                                    horas(fecha);
                                }}
                            />
                        )}

                        <div>
                            {horasTarget.length > 0 && (
                                <p
                                    style={{ marginBottom: "0" }}
                                    className="titulo_3"
                                >
                                    ¿A que hora quieres la cita?
                                </p>
                            )}
                            <div className="horizontal_horas" id="horas_select">
                                {horasTarget.map((target) => target)}
                            </div>
                        </div>

                        <p className="titulo_3">¿Para quién es la cita?</p>
                        <div class="input-field">
                            <input
                                onChange={leerDato}
                                id="nombre"
                                name="nombre"
                                type="text"
                                class="validate"
                                required
                            />
                            <label
                                className="text_2"
                                style={{ fontWeight: "bold" }}
                                for="nombre"
                            >
                                Nombre
                            </label>
                        </div>
                        <div class="input-field">
                            <input
                                onChange={leerDato}
                                id="email"
                                name="email"
                                type="email"
                                class="validate"
                                required
                            />
                            <label
                                className="text_2"
                                style={{ fontWeight: "bold" }}
                                for="email"
                            >
                                E-mail
                            </label>
                        </div>
                        <div class="input-field">
                            <input
                                onChange={leerDato}
                                id="telefono"
                                name="telefono"
                                type="text"
                                class="validate"
                                required
                            />
                            <label
                                className="text_2"
                                style={{ fontWeight: "bold" }}
                                for="telefono"
                            >
                                Teléfono
                            </label>
                        </div>

                        <div class="container divider divider_1" />

                        <p className="titulo_3">Información adicional</p>
                        <p className="text_2" style={{ fontWeight: "bold" }}>
                            ¿Es tu primera vez con la Dra. Mariana?
                        </p>
                        <div>
                            <p>
                                <label>
                                    <input
                                        onChange={leerDato}
                                        name="primera_cita"
                                        value={"Sí"}
                                        type="radio"
                                        required
                                    />
                                    <span className="text_2">Sí</span>
                                </label>
                            </p>
                            <p>
                                <label>
                                    <input
                                        onChange={leerDato}
                                        name="primera_cita"
                                        value={"No"}
                                        type="radio"
                                        required
                                    />
                                    <span className="text_2">No</span>
                                </label>
                            </p>
                        </div>

                        <p className="text_2" style={{ fontWeight: "bold" }}>
                            ¿Cuál es el servicio que necesitas?
                        </p>
                        {state.hospital === "" && <p>Escoja un Hospital</p>}
                        {state.hospital === "Hospital Médica Sur" && (
                            <div style={{ display: "flex" }}>
                                <div
                                    className="col s12 l6"
                                    style={{ padding: "0px" }}
                                >
                                    <p>
                                        <label>
                                            <input
                                                onChange={leerServicio}
                                                name="servicio"
                                                value={"Ultrasonido ocular"}
                                                type="checkbox"
                                            />
                                            <span className="text_2">
                                                Ultrasonido ocular
                                            </span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input
                                                onChange={leerServicio}
                                                name="servicio"
                                                value={"Ultrabiomicroscopía"}
                                                type="checkbox"
                                            />
                                            <span className="text_2">
                                                Ultrabiomicroscopía
                                            </span>
                                        </label>
                                    </p>
                                    <p style={{ marginBottom: "0" }}>
                                        <label>
                                            <input
                                                onChange={leerServicio}
                                                name="servicio"
                                                value={"Microscopía especular"}
                                                type="checkbox"
                                            />
                                            <span className="text_2">
                                                Microscopía especular
                                            </span>
                                        </label>
                                    </p>
                                </div>
                                <div
                                    className="col s12 l6"
                                    style={{ padding: "0px" }}
                                >
                                    <p>
                                        <label>
                                            <input
                                                onChange={leerServicio}
                                                name="servicio"
                                                value={"OPD"}
                                                type="checkbox"
                                            />
                                            <span className="text_2">OPD</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input
                                                onChange={leerServicio}
                                                name="servicio"
                                                value={
                                                    "Cálculo de lente intraocular"
                                                }
                                                type="checkbox"
                                            />
                                            <span className="text_2">
                                                Cálculo de lente intraocular
                                            </span>
                                        </label>
                                    </p>
                                </div>
                            </div>
                        )}
                        {state.hospital === "Clínica Médica Polanco" && (
                            <div style={{ display: "flex" }}>
                                <div
                                    className="col s12 l6"
                                    style={{ padding: "0px" }}
                                >
                                    <p>
                                        <label>
                                            <input
                                                onChange={leerServicio}
                                                name="servicio"
                                                value={"Ultrasonido ocular"}
                                                type="checkbox"
                                            />
                                            <span className="text_2">
                                                Ultrasonido ocular
                                            </span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input
                                                onChange={leerServicio}
                                                name="servicio"
                                                value={
                                                    "Cálculo de lente intraocular"
                                                }
                                                type="checkbox"
                                            />
                                            <span className="text_2">
                                                Cálculo de lente intraocular
                                            </span>
                                        </label>
                                    </p>
                                </div>
                                <div
                                    className="col s12 l6"
                                    style={{ padding: "0px" }}
                                >
                                    <p>
                                        <label>
                                            <input
                                                onChange={leerServicio}
                                                name="servicio"
                                                value={
                                                    "Paquimetría ultrasónica"
                                                }
                                                type="checkbox"
                                            />
                                            <span className="text_2">
                                                Paquimetría ultrasónica
                                            </span>
                                        </label>
                                    </p>
                                </div>
                            </div>
                        )}

                        <p className="text_2" style={{ fontWeight: "bold" }}>
                            ¿Para cuál de tus ojos necesitas este servicio?
                        </p>
                        <p>
                            <label>
                                <input
                                    onChange={leerDato}
                                    name="ojo"
                                    value={"Ojo derecho"}
                                    type="radio"
                                    required
                                />
                                <span className="text_2">Ojo derecho</span>
                            </label>
                        </p>
                        <p>
                            <label>
                                <input
                                    onChange={leerDato}
                                    name="ojo"
                                    value={"Ojo izquierdo"}
                                    type="radio"
                                    required
                                />
                                <span className="text_2">Ojo izquierdo</span>
                            </label>
                        </p>
                        <p>
                            <label>
                                <input
                                    onChange={leerDato}
                                    name="ojo"
                                    value={"Ambos ojos"}
                                    type="radio"
                                    required
                                />
                                <span className="text_2">Ambos ojos</span>
                            </label>
                        </p>

                        <p className="text_2" style={{ fontWeight: "bold" }}>
                            Favor de adjuntar una foto de tu orden o receta para
                            el estudio.
                        </p>
                        <div class="file-field input-field">
                            <div class="btn">
                                <span className="text_2">Subir archivo</span>
                                <input
                                    onChange={(e) => {
                                        setArchivo(e.target.files[0]);
                                    }}
                                    accept="image/*"
                                    type="file"
                                />
                            </div>
                            <div class="file-path-wrapper">
                                <input
                                    onChange={(e) => {
                                        setArchivo(e.target.files[0]);
                                    }}
                                    class="file-path validate"
                                    type="text"
                                />
                            </div>
                        </div>

                        <p className="text_2" style={{ fontWeight: "bold" }}>
                            ¿Tienes algún comentario o requerimiento para tu
                            cita?
                        </p>
                        <div class="input-field">
                            <textarea
                                onChange={leerDato}
                                name="mensaje"
                                id="mensaje"
                                class="materialize-textarea"
                                required
                            ></textarea>
                            <label className="text_2" for="mensaje">
                                Mensaje
                            </label>
                        </div>
                    </div>
                    <div
                        className="col s12 l5"
                        style={{ position: "sticky", top: "20px" }}
                    >
                        <div className="center contenedor_agenda sombras">
                            <p className="titulo_3">Tu cita:</p>
                            <p className="text_2">
                                En el consultorio:{" "}
                                <b style={{ color: "#000000" }}>
                                    {state.hospital}
                                </b>
                            </p>
                            {stateContext.state.dia ? (
                                <p className="text_2">
                                    Fecha:{" "}
                                    <b style={{ color: "#000000" }}>
                                        {stateContext.state.dia} de{" "}
                                        {stateContext.state.mes} del{" "}
                                        {stateContext.state.anio}
                                    </b>
                                </p>
                            ) : (
                                <p className="text_2">Fecha:</p>
                            )}
                            <p className="text_2">
                                Hora:{" "}
                                <b style={{ color: "#000000" }}>
                                    {HORAS[horasState]}
                                </b>
                            </p>
                            <p className="text_2">
                                Paciente:{" "}
                                <b style={{ color: "#000000" }}>
                                    {state.nombre}
                                </b>
                            </p>
                            <p className="text_2">
                                Info adicional:{" "}
                                <b style={{ color: "#000000" }}>
                                    {state.mensaje}
                                </b>
                            </p>
                            <button
                                className="button_4"
                                type="submit"
                                style={{ marginTop: "30px" }}
                                href="/servicios"
                            >
                                Confirmar mi cita
                            </button>
                        </div>
                    </div>
                </form>
            </section>
        </Fragment>
    );
};

export default compose(
    firestoreConnect((props) => [
        {
            collection: "horarios",
        },
    ]),
    connect(({ firestore: { ordered } }, props) => ({
        horarios: ordered.horarios,
    }))
)(Agenda);
