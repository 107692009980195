import React, { Fragment, useState } from 'react'
import { firestoreConnect } from 'react-redux-firebase'

import firebaseHelper from '../Helpers/Firebase'

import Carusel from '../Components/CarruselServicios/Carusel'
import Galeria from '../Components/Galeria/Galeria'

import img1 from '../Images/1.png'
import img1_1 from '../Images/1_1.png'
import img2 from '../Images/2.png'
import img3 from '../Images/3.png'

import servicio1 from '../Images/servicios/externas/1.png'
import servicio2 from '../Images/servicios/externas/2.png'
import servicio3 from '../Images/servicios/externas/3.png'

import whats_b from '../Images/iconos/whats_b.svg'

const Index = (props) => {
  const [state, setState] = useState({
    nombre: '',
    telefono: '',
    email: '',
    mensaje: '',
    estado: false,
    archivado: false
  })

  const leerDato = (e) => {
    e.preventDefault()
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const enviar = (e) => {
    e.preventDefault()
    const { firestore } = props

    firebaseHelper.agregar('mensajes', firestore, state, '/agradecimiento')
  }

  return (
    <Fragment>
      <img
        src={img1}
        className="mobil_off"
        alt="Estudios oftalmológicos"
        style={{ width: '100%', marginBottom: '50px' }}
      />
      <div
        className="desktop_off corte_tablet"
        style={{ marginBottom: '50px' }}
      >
        <img
          src={img1_1}
          alt="Estudios oftalmológicos"
          style={{ width: '100%', position: 'relative', bottom: '100%' }}
        />
      </div>

      <section
        className="row container"
        style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
      >
        <div className="col l6 s12">
          <img
            src={img2}
            alt="Dra. Mariana Mayorquín Ruiz"
            className="imagen_index_1"
          />
        </div>
        <div className="col l6 s12 center_mobil">
          <p
            style={{ color: '#363636', fontWeight: 'normal' }}
            className="titulo_1 margin_1"
          >
            Dra. Mariana
          </p>
          <p
            style={{ color: '#363636', fontWeight: 'normal' }}
            className="titulo_1 margin_1 linea_2"
          >
            Mayorquín Ruiz
          </p>
          <p style={{ fontWeight: 'bold' }}>
            Oftalmóloga con especialidades en Glaucoma y Ultrasonido Ocular
          </p>
          <p className="text_1" style={{ marginBottom: '35px' }}>
            Más de 15 años brindando un impecable servicio en conjunto con el
            equipo más innovador a todos mis pacientes.
          </p>
          <a className="button_1" href="/dra-marina">
            Leer más
          </a>
        </div>
      </section>

      <section className="contenedor_fondo">
        <div className="fondo_1" />
        <div className="container contenedor_md">
          <p className="titulo_2 linea_3" style={{ textAlign: 'center' }}>
            Servicios
          </p>

          <div
            className="contenedor_servicios_index mobil_off"
            style={{ paddingBottom: '40px' }}
          >
            <div className="servicios_index">
              <a href="/ultrasonido-ocular">
                <img
                  src={servicio1}
                  alt="Ultrasonido ocular"
                  style={{ width: '100%' }}
                />
              </a>
              <div>
                <p className="margin_1 text_2">Ultrasonido ocular</p>
                <a className="text_3" href="/ultrasonido-ocular">
                  Ver más
                </a>
              </div>
            </div>
            <div className="servicios_index">
              <a href="/ultrabiomicroscopia">
                <img
                  src={servicio2}
                  alt="Ultrasonido ocular"
                  style={{ width: '100%' }}
                />
              </a>
              <div>
                <p className="margin_1 text_2">Ultrabiomicroscopía</p>
                <a className="text_3" href="/ultrabiomicroscopia">
                  Ver más
                </a>
              </div>
            </div>
            <div className="servicios_index">
              <a href="/microscopia-especular">
                <img
                  src={servicio3}
                  alt="Ultrasonido ocular"
                  style={{ width: '100%' }}
                />
              </a>
              <div>
                <p className="margin_1 text_2">Microscopía especular</p>
                <a className="text_3" href="/microscopia-especular">
                  Ver más
                </a>
              </div>
            </div>
          </div>

          <div className="desktop_off">
            <Carusel />
          </div>

          <div className="padding_1" style={{ textAlign: 'center' }}>
            <a className="button_2" href="/servicios">
              Ver todos
            </a>
          </div>

          <div style={{ textAlign: 'center' }}>
            <img
              src={img3}
              alt="¿Qué estudios requiere tu oftalmólogo?"
              style={{ width: '100%' }}
            />
            <p className="titulo_2" style={{ marginTop: '0' }}>
              ¿Qué estudios requiere tu oftalmólogo?
            </p>
            <a
              className="button_3"
              target="_blanck"
              href="https://api.whatsapp.com/send?phone=+525559670113&text=Hola,%20me%20gustaría%20agendar%20una%20cita"
            >
              Agenda tu cita
            </a>
          </div>
        </div>
      </section>

      <section style={{ textAlign: 'center', marginBottom: '50px' }}>
        <p className="titulo_2 linea_4">Galería</p>
        <Galeria />
        <a className="button_1" href="/dra-marina">
          Ver más
        </a>
      </section>

      <section className="fondo_2" style={{ height: 'auto' }}>
        <form onSubmit={enviar} className="container center">
          <p className="titulo_2 linea_5" style={{ textAlign: 'center' }}>
            ¿Tienes alguna duda?
          </p>
          <a
            className="button_2_1"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '7px',
              width: 'fit-content',
              margin: 'auto'
            }}
            href="https://api.whatsapp.com/send?phone=+525559670113&text=Hola,%20me%20gustaría%20agendar%20una%20cita"
          >
            <img
              id="imagenes"
              src={whats_b}
              style={{ width: '20px' }}
              alt="WhatsApp Dra. Mariana Mayorquín Ruiz"
            />
            Escríbenos
          </a>
          {/* <p className="text_1">
            Escríbenos y nos comunicaremos contigo lo más pronto posible.
          </p>
          <div className="row">
            <div className="col s12 m4">
              <input
                onChange={leerDato}
                type="text"
                name="nombre"
                placeholder="Nombre"
                className="input_form"
                required
              />
            </div>
            <div className="col s12 m4">
              <input
                onChange={leerDato}
                type="tel"
                name="telefono"
                placeholder="Teléfono"
                className="input_form"
                minlength="8"
                maxlength="10"
                pattern="[0-9]{10}"
                required
              />
            </div>
            <div className="col s12 m4">
              <input
                onChange={leerDato}
                type="email"
                name="email"
                placeholder="E-mail"
                className="input_form"
                required
              />
            </div>
            <div className="col s12">
              <textarea
                onChange={leerDato}
                name="mensaje"
                placeholder="Mensaje"
                className="area_form"
                required
              />
            </div>
            <button
              className="button_2"
              type="submit"
              style={{ marginTop: '30px' }}
            >
              ENVIAR
            </button>
          </div> */}
        </form>
      </section>
    </Fragment>
  )
}

export default firestoreConnect()(Index)
