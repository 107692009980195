import React from 'react'

import img2 from '../../Images/servicios/internas/2.png'
import img2_1 from '../../Images/servicios/internas/2_1.png'

const Ultrabiomicroscopia = () => {
  return (
    <section style={{ marginBottom: '80px' }} className="container text_1">
      <p className="titulo_1 linea_3">Ultrabiomicroscopía</p>
      <img src={img2} alt="Ultrabiomicroscopía" style={{ width: '100%' }} />
      <p className="titulo_3">Descripción</p>
      <p>
        La ultrabiomicroscopía conocida mejor como <b>UBM</b> es también un tipo
        de ultrasonido ocular, que funciona específicamente para el área más
        anterior de los ojos.
      </p>
      <p>
        En éste sitio hay varias estructuras que pueden ser revisadas por la
        UBM: el iris, el ángulo, la córnea, el cuerpo ciliar, etc.
      </p>
      <p>
        Hay patologías que pueden afectar a cada una de esas estructuras o
        relacionarse con otras, como el glaucoma.
      </p>
      <img
        src={img2_1}
        className="imagen_servicios"
        alt="Ultrabiomicroscopía"
      />
      <p style={{ fontWeight: 'bold' }}>¿Cuál es el procedimiento?</p>
      <p>
        La <b>UBM</b> se suele realizar a través de una membrana que contiene
        agua en su interior y está acoplada al transductor.
      </p>
      <p>Esto se apoya sobre el ojo previamente anestesiado.</p>
      <p>
        De las imágenes obtenidas, se realizan varias mediciones y hacemos una
        interpretación del estudio.
      </p>

      <div className="center" style={{ marginTop: '45px' }}>
        <a
          className="button_3"
          target="_blanck"
          href="https://api.whatsapp.com/send?phone=+525559670113&text=Hola,%20me%20gustaría%20agendar%20una%20cita"
        >
          Agenda tu cita
        </a>
      </div>
    </section>
  )
}

export default Ultrabiomicroscopia
