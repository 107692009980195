import React, { Component } from 'react'
import M from 'materialize-css'

import tel from '../Images/iconos/phone.svg'
import faceredes from '../Images/iconos/face_b.svg'
import whats_b from '../Images/iconos/whats_b.svg'
import insta from '../Images/iconos/insta_b.svg'

class Redes extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount = () => {
    var elems = document.querySelectorAll('.fixed-action-btn')
    M.FloatingActionButton.init(elems)
  }

  render() {
    return [
      <div
        style={{ marginBottom: '20px' }}
        className="fixed-action-btn"
        id="boton-redes"
      >
        <a
          href="#!"
          className="btn-floating btn-large button_redes redes_color_1 pulse"
        >
          <img id="imagenes" alt="imagen face" src={tel} />
        </a>
        <ul>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              className="btn-floating button_redes_1 redes_color_2"
              href="https://www.facebook.com/people/Dra-Mariana-Mayorquín-Ruiz/100089109151488"
            >
              <img id="imagenes" alt="imagen face" src={faceredes} />
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              className="btn-floating button_redes_1 redes_color_3"
              href="https://www.instagram.com/imagenoftalmologica"
            >
              <img id="imagenes" alt="imagen face" src={insta} />
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              className="btn-floating button_redes_1 redes_color_4"
              href="https://api.whatsapp.com/send?phone=+525559670113&text=Hola,%20me%20gustaría%20agendar%20una%20cita"
            >
              <img
                id="imagenes"
                src={whats_b}
                alt="WhatsApp Dra. Mariana Mayorquín Ruiz"
              />
            </a>
          </li>
        </ul>
      </div>
    ]
  }
}

export default Redes
