import React from 'react'

import img5 from '../../Images/servicios/internas/5.png'
import img5_1 from '../../Images/servicios/internas/5_1.png'

const CalculoIntraocular = () => {
  return (
    <section style={{ marginBottom: '80px' }} className="container text_1">
      <p className="titulo_1 linea_3">Cálculo de lente intraocular</p>
      <img
        src={img5}
        alt="Cálculo de lente intraocular"
        style={{ width: '100%' }}
      />
      <p className="titulo_3">Descripción</p>
      <p>
        La principal causa de ceguera reversible en el mundo es la catarata. A
        un cristalino opaco se le llama catarata.
      </p>
      <p>
        Una vez que tu cirujano retira la catarata, implanta un lente
        intraocular dentro de tu ojo. Su función principal es mantener la
        función refractiva, que es natural al cristalino.
      </p>
      <p>
        Es decir, el lente intraocular suple la función de “lupa” que es natural
        en el cristalino dentro del ojo.
      </p>
      <img
        src={img5_1}
        className="imagen_servicios"
        alt="Cálculo de lente intraocular"
      />
      <p style={{ fontWeight: 'bold' }}>¿Cuál es el procedimiento?</p>
      <p>
        La graduación de ese lente intraocular debe ser calculada a partir de
        varias mediciones de tu ojo, mismas que se ingresan en fórmulas
        matemáticas para obtener la graduación de tu lente.
      </p>

      <div className="center" style={{ marginTop: '45px' }}>
        <a
          className="button_3"
          target="_blanck"
          href="https://api.whatsapp.com/send?phone=+525559670113&text=Hola,%20me%20gustaría%20agendar%20una%20cita"
        >
          Agenda tu cita
        </a>
      </div>
    </section>
  )
}

export default CalculoIntraocular
