import React, { Fragment } from 'react'
import './Nosotros.css'

import img4 from '../../Images/4.png'
import whats from '../../Images/iconos/whats_b.svg'
import insta from '../../Images/iconos/insta_b.svg'
import face from '../../Images/iconos/face_b.svg'
import mail from '../../Images/iconos/mail_b.svg'
import home from '../../Images/iconos/home_2.svg'
import phone from '../../Images/iconos/phone_2.svg'

const Nosotros = () => {
  return (
    <Fragment>
      <section className="container grid_1">
        <div>
          <img
            src={img4}
            alt="Coctora Marina"
            style={{ width: '100%', paddingRight: '20px' }}
          />
        </div>
        <div>
          <div>
            <p
              className="titulo_3"
              style={{ margin: '0', fontWeight: 'normal' }}
            >
              Dra. Mariana Mayorquín Ruiz
            </p>
            <p
              className="text_4 linea_6"
              style={{ margin: '0', fontWeight: 'bold' }}
            >
              Oftalmóloga{' '}
            </p>
          </div>
        </div>
        <div>
          <p className="text_4" style={{ fontWeight: 'bold' }}>
            Especialista en Glaucoma y Ultrasonido Ocular.
          </p>
          <p className="text_1">
            Mas de 15 años brindando un impecable servicio en conjunto con el
            quipo más innovador a todos mis pacientes.
          </p>
        </div>
      </section>

      <div class="container divider divider_1" />

      <section className="container row text_1">
        <p className="titulo_3 center_movil">Acerca de mi:</p>
        <div className="col s12 m12 l6">
          <div className="servicios">
            <div className="check" />
            <p>
              Jefe del Servicio de Ultrasonido ocular en la Asociación para
              Evitar la Ceguera en México.
            </p>
          </div>
          <div className="servicios">
            <div className="check" />
            <p>Ex-Presidente del Centro Mexicano de Ultrasonido ocular.</p>
          </div>
          <div className="servicios">
            <div className="check" />
            <p>
              Catedrática de la Alta Especialidad de Ultrasonido ocular por la
              Universidad Nacional Autónoma de México.
            </p>
          </div>
        </div>
        <div className="col s12 m12 l6">
          <div className="servicios">
            <div className="check" />
            <p>
              Miembro del Tlalpan Team, grupo especializado en Trasplante
              Compuesto Vascularizado.
            </p>
          </div>
          <div className="servicios">
            <div className="check" />
            <p>
              Autora y coautora de varios artículos científicos, capítulos de
              libros y ponente de charlas en congresos nacionales e
              internacionales.
            </p>
          </div>
        </div>
      </section>

      <div class="container divider divider_1" />

      <section className="container row text_1">
        <p className="titulo_3 center_movil">Servicios</p>
        <div className="col s12 m12 l6">
          <div className="servicios">
            <div className="check" />
            <p>
              <a
                href="/ultrasonido-ocular"
                style={{ color: '#626262', textDecorationLine: 'underline' }}
              >
                Ultrasonido ocular
              </a>
            </p>
          </div>
          <div className="servicios">
            <div className="check" />
            <p>
              <a
                href="/ultrabiomicroscopia"
                style={{ color: '#626262', textDecorationLine: 'underline' }}
              >
                Ultrabiomicroscopía
              </a>
            </p>
          </div>
          <div className="servicios">
            <div className="check" />
            <p>
              <a
                href="/microscopia-especular"
                style={{ color: '#626262', textDecorationLine: 'underline' }}
              >
                Microscopía especular
              </a>
            </p>
          </div>
        </div>
        <div className="col s12 m12 l6">
          <div className="servicios">
            <div className="check" />
            <p>
              <a
                href="/topografia-corneal"
                style={{ color: '#626262', textDecorationLine: 'underline' }}
              >
                OPD
              </a>
            </p>
          </div>
          <div className="servicios">
            <div className="check" />
            <p>
              <a
                href="/calculo-de-lente-intraocular"
                style={{ color: '#626262', textDecorationLine: 'underline' }}
              >
                Cálculo de lente intraocular
              </a>
            </p>
          </div>
          <div className="servicios">
            <div className="check" />
            <p>
              <a
                href="/paquimetria-ultrasonica"
                style={{ color: '#626262', textDecorationLine: 'underline' }}
              >
                Paquimetría ultrasónica
              </a>
            </p>
          </div>
        </div>
      </section>

      <div class="container divider divider_1" />

      <section
        className="container row text_1 center_movil"
        style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'stretch' }}
      >
        <p className="titulo_3 col s12">Información de contacto</p>
        <div className="col s12 l6">
          <div className="contenedor_flex_1">
            <div className="contacto_img">
              <img src={whats} alt="" />
            </div>
            <p>
              <a
                style={{ color: '#626262' }}
                target="_blank"
                rel="noreferrer"
                href="https://api.whatsapp.com/send?phone=+525559670113&text=Hola,%20me%20gustaría%20agendar%20una%20cita"
              >
                Whatsapp: +52 5559670113
              </a>
            </p>
          </div>
          <div className="contenedor_flex_1">
            <div style={{ display: 'flex', gap: '10px' }}>
              <div className="contacto_img">
                <img src={face} alt="" />
              </div>
              <div className="contacto_img">
                <img src={insta} alt="" />
              </div>
            </div>
            <p>
              <a style={{ color: '#626262' }} href="!#">
                @imagenoftalmologica
              </a>
            </p>
          </div>
          <div className="contenedor_flex_1">
            <div className="contacto_img">
              <img style={{ height: '60%' }} src={mail} alt="" />
            </div>
            <p>
              <a
                style={{ color: '#626262' }}
                target="_blank"
                rel="noreferrer"
                href="mailto:info@imagenoftalmologica.com?subject=Solicitud%20de%20informaci%F3n&body=Buen%20d%EDa%2C%20me%20podr%EDa%20brindar%20informaci%F3n%20de%20sus%20servicios."
              >
                info@imagenoftalmologica.com
              </a>
            </p>
          </div>
        </div>
        <div
          className="col s12 l6"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <a
            className="button_3"
            target="_blanck"
            href="https://api.whatsapp.com/send?phone=+525559670113&text=Hola,%20me%20gustaría%20agendar%20una%20cita"
          >
            Agenda tu cita
          </a>
        </div>
        <div className="col s12 l6">
          <p
            style={{
              fontWeight: 'bold',
              color: '#4EB5EA',
              marginBottom: '0px'
            }}
          >
            Hospital Médica Sur.
          </p>
          <div className="contenedor_flex_2">
            <img style={{ width: '20px', height: '20px' }} src={home} alt="" />
            <p style={{ marginTop: '10px' }}>
              <a
                style={{ color: '#626262' }}
                target="_blank"
                rel="noreferrer"
                href="https://goo.gl/maps/YhkWCvB9s16zonsV6"
              >
                Puente de Piedra 150, Toriello Guerra, Tlalpan, 14050 Ciudad de
                México, CDMX.
              </a>
            </p>
          </div>
          <div className="contenedor_flex_2">
            <img style={{ width: '20px', height: '20px' }} src={phone} alt="" />
            <p>
              <a
                style={{ color: '#626262', textDecorationLine: 'underline' }}
                href="tel:+525553353694"
                target="_blank"
                rel="noreferrer"
              >
                55 5335 3694
              </a>
              <br />
              <a
                style={{ color: '#626262', textDecorationLine: 'underline' }}
                href="tel:+525554247200"
                target="_blank"
                rel="noreferrer"
              >
                55 5424 7200 ext. 4334
              </a>
            </p>
          </div>
        </div>
        <div className="col s12 l6">
          <p
            style={{
              fontWeight: 'bold',
              color: '#4EB5EA',
              marginBottom: '0px'
            }}
          >
            Clínica Médica Polanco
          </p>
          <div className="contenedor_flex_2">
            <img style={{ width: '20px', height: '20px' }} src={home} alt="" />
            <p style={{ marginTop: '10px' }}>
              <a
                style={{ color: '#626262' }}
                target="_blank"
                rel="noreferrer"
                href="https://goo.gl/maps/EcDuwCMxHUSuYExy8"
              >
                Edgar Allan Poe 108, Col. Polanco, Alcaldia Miguel Hidalgo,
                11550 Ciudad de México, CDMX.
              </a>
            </p>
          </div>
          <div className="contenedor_flex_2">
            <img style={{ width: '20px', height: '20px' }} src={phone} alt="" />
            <p>
              <a
                style={{ color: '#626262', textDecorationLine: 'underline' }}
                href="tel:+525552811607"
                target="_blank"
                rel="noreferrer"
              >
                55 5281 1607
              </a>
              <br />
              <a
                style={{ color: '#626262', textDecorationLine: 'underline' }}
                href="tel:+525552815001"
                target="_blank"
                rel="noreferrer"
              >
                55 5281 5001
              </a>
            </p>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default Nosotros
