import React, { Fragment } from 'react';
import "./Carusel.css";

import servicio1 from "../../Images/servicios/externas/1.png";
import servicio2 from "../../Images/servicios/externas/2.png";
import servicio3 from "../../Images/servicios/externas/3.png";

const Carusel = () => {

    return (
        <Fragment>
            <div class="wrapper">
                <section class="horizontal-slider">
                    <div className='servicios_index card'>
                        <img src={servicio1} alt="Ultrasonido ocular" style={{width:"250px"}} />
                        <div>
                            <p className='margin_1 text_2'>Ultrasonido ocular</p>
                            <a className='text_3' href="#!">Ver más</a>
                        </div>
                    </div>
                    <div className='servicios_index card'>
                        <img src={servicio2} alt="Ultrasonido ocular" style={{width:"250px"}} />
                        <div>
                            <p className='margin_1 text_2'>Ultrabiomicroscopía</p>
                            <a className='text_3' href="#!">Ver más</a>
                        </div>
                    </div>
                    <div className='servicios_index card'>
                        <img src={servicio3} alt="Ultrasonido ocular" style={{width:"250px"}} />
                        <div>
                            <p className='margin_1 text_2'>Microscopía especular</p>
                            <a className='text_3' href="#!">Ver más</a>
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    );
};

export default Carusel;