import React from 'react'

import img3 from '../../Images/servicios/internas/3.png'
import img3_1 from '../../Images/servicios/internas/3_1.png'

const MicroscopiaEspecular = () => {
  return (
    <section style={{ marginBottom: '80px' }} className="container text_1">
      <p className="titulo_1 linea_3">Microscopía especular</p>
      <img src={img3} alt="Microscopía especular" style={{ width: '100%' }} />
      <p className="titulo_3">Descripción</p>
      <p>
        Es una fotografía de la parte posterior de tu córnea, llamada endotelio
        corneal.
      </p>
      <p>
        Las células que tapizan la cara interna de tu córnea tienen diversas
        características en forma, número, tamaño, etc. Son muy especiales porque
        no se reproducen, de manera que con las que nacemos nos quedamos toda la
        vida.
      </p>
      <p>
        Algunas situaciones pueden provocar que se pierdan en el transcurso del
        tiempo.
      </p>
      <p>
        A tu especialista le puede interesar el estado de tu endotelio corneal
        por muchas razones, por ejemplo, previo a una cirugía, trasplante de
        córnea, enfermedades propias de éstas células, etc.
      </p>
      <img
        src={img3_1}
        className="imagen_servicios"
        alt="Microscopía especular"
      />
      <p style={{ fontWeight: 'bold' }}>¿Cuál es el procedimiento?</p>
      <p>
        La prueba se realiza mediante un microscopio especular de no contacto,
        es sencillo; se te pedirá que mires a un punto luminoso mientras tomamos
        las imágenes.
      </p>

      <div className="center" style={{ marginTop: '45px' }}>
        <a
          className="button_3"
          target="_blanck"
          href="https://api.whatsapp.com/send?phone=+525559670113&text=Hola,%20me%20gustaría%20agendar%20una%20cita"
        >
          Agenda tu cita
        </a>
      </div>
    </section>
  )
}

export default MicroscopiaEspecular
