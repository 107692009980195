import React, {Fragment, useEffect, useState, useContext}  from 'react';
import { dataContext } from '../../Context/DataContext';

import "./Calendario.css";


const Calendario = props => {
    const HOY = new Date();
    const HOY_COPIA = new Date();

    // note: si queremos incrementar el tiempo de muestar del calendario lo haremos sumando a esta fecha
    // un mes => 31
    const DIASHABILES = new Date(HOY_COPIA.setDate(HOY_COPIA.getDate() + 93));

    // parametros que se pasan
    const BLOQUEADO = props.bloqueado || false;
    const DIASBLOQUEADOS = props.diasBloqueados || [];
    const SEMANASBLOQUEADAS = props.semanaBloquedas || []

    const [diasMes, setDiasMes] = useState([]);
    const semana = ["LUN", "MAR", "MIE", "JUE", "VIE", "SAB", "DOM"];
    const mesString = [
        "Enero",
        "Febrero", 
        "Marzo", 
        "Abril", 
        "Mayo", 
        "Junio", 
        "Julio", 
        "Agosto", 
        "Septiembre", 
        "Octubre", 
        "Noviembre", 
        "Diciembre"
    ];
    const [mes, setMes] = useState(HOY.getMonth());
    const [anio, setAnio] = useState(HOY.getFullYear());

    const state = useContext(dataContext);

    useEffect(() => {
        calcularCalendario(new Date().getMonth(), new Date().getFullYear());
        // eslint-disable-next-line
    }, []);

    const proximoMes = () => {
        document.getElementById("formCalendar").reset();

        let numeroMes = mes + 1;
        let numeroAnio = anio;
        if (numeroMes === 12) {
            numeroMes = 0;
            numeroAnio = numeroAnio + 1;
            setAnio(numeroAnio);
        }
        setMes(numeroMes);
        calcularCalendario(numeroMes, numeroAnio);
    }

    const mesAnterior = () => {
        document.getElementById("formCalendar").reset();

        let numeroMes = mes - 1;
        let numeroAnio = anio;
        if (numeroMes < 0) {
            numeroMes = 11;
            numeroAnio = numeroAnio - 1;
            setAnio(numeroAnio);
        }
        setMes(numeroMes);
        calcularCalendario(numeroMes, numeroAnio);
    }

    const calcularCalendario = (mes, anio) => {
        let buscarDia = false;
        let buscarSemana = false;
        let priemDia = new Date(anio, mes, 1);

        // note: si queremos incrementar el tiempo de muestar del calendario lo haremos sumando a esta fecha
        let ultimoDia = new Date(anio, mes + 3, 0);
        const mesArray = [];

        let obtenerDiaSemana = () => {
            if (priemDia.getDay() - 1 >= 0 ) {
                return(priemDia.getDay() - 1)
            } else {
                return(6)
            }
        };

        let diaSemana = obtenerDiaSemana();
        for (let diaNulo = 0; diaNulo < diaSemana; diaNulo++) {
            if (diaNulo % 7 === 0) {
                //lunes
                mesArray.push({dia: false, class:"calendario_lunes calendario_vacio"});
            } else {
                //demas dias
                mesArray.push({dia: false, class:"calendario_dia calendario_vacio"});
            }
        }

        for (let dia = 1; dia < ultimoDia.getDate() + 1; dia++) {
            let fecha = new Date(anio, mes, dia);
            let json;
            if (fecha.getDay() === 1) {
                //lunes
                json = {
                    dia,
                    class: "calendario_lunes"
                }
            } else {
                //demas dias
                json = {
                    dia,
                    class: "calendario_dia"
                }
            }

            if (DIASBLOQUEADOS.length > 0) {
                // eslint-disable-next-line 
                buscarDia = DIASBLOQUEADOS.find(dia => {
                    let fechaPrueba = new Date(dia.seconds * 1000);
                    if (fechaPrueba.getFullYear() === fecha.getFullYear() && fechaPrueba.getDate() === fecha.getDate() && fechaPrueba.getMonth() === fecha.getMonth()) {
                        return true;
                    }
                });
            }

            if (SEMANASBLOQUEADAS.length > 0) {
                buscarSemana = SEMANASBLOQUEADAS.indexOf(fecha.getDay().toString());
            }

            if (HOY > fecha || fecha > DIASHABILES || BLOQUEADO || buscarDia || buscarSemana < 0) {
                json.disabled = "disabled"
            }
            mesArray.push(json);
        }

        if (ultimoDia.getDay() !== 0) {
            for (let diasFinales = 0; diasFinales< (7 - ultimoDia.getDay()); diasFinales++) {
                mesArray.push({dia: false, class:"calendario_dia calendario_vacio"});
            }
        }

        setDiasMes(mesArray);
    }

    const seleccionarFecha = dia => {
        state.setState({dia, mes:mesString[mes], anio});

        // retornamos el valor de la fecha seleccionada.
        props.onSelect(new Date(anio, mes, dia));
    }

    return (
        <Fragment>
            <div className='calendario'>
                <div className='header_calendario'>
                    <div className='flecha izq' onClick={mesAnterior} />
                    <div className='titulo_4_nv center_movil' style={{fontWeight:"bold"}}>
                        <p>{mesString[mes]} {anio}</p>
                    </div>
                    <div className='flecha dec' onClick={proximoMes} />
                </div>
                <div className='calendario_semanas'>
                    {semana.map(semana => (
                        <div className='dia_semana'>
                            <p>{semana}</p>
                        </div>
                    ))}
                </div>
                <form id='formCalendar'>
                    <div className='calendario_dias'>
                        {diasMes.map(dia => (
                            dia.dia ? (
                                dia.disabled ? (
                                    <label className={'disabled ' + dia.class}>
                                        <input onClick={() => seleccionarFecha(dia.dia)} name="dia" type="radio" disabled="disabled" />
                                        {dia.dia === HOY ? (
                                            <div className='select_dia hoy' title={dia.dia}></div>
                                        ) : (
                                            <div className='select_dia' title={dia.dia}></div>
                                        )}
                                    </label>
                                ) : (
                                    <label className={'dia ' + dia.class}>
                                        <input onClick={() => seleccionarFecha(dia.dia)} name="dia" type="radio" />
                                        {dia.dia === HOY ? (
                                            <div className='select_dia hoy' title={dia.dia}></div>
                                        ) : (
                                            <div className='select_dia' title={dia.dia}></div>
                                        )}
                                    </label>
                                )
                            ) : (
                                <div className={'dia_vacio ' + dia.class} />
                            )
                        ))}
                    </div>
                </form>
            </div>
        </Fragment>
    );
};

export default Calendario;