import React from 'react';

const Mensaje = () => {
    return (
        <div>
            <h1>Mensaje enviado correctamente</h1>
        </div>
    );
};

export default Mensaje;