import React, { Fragment } from "react";

import servicio1 from "../Images/servicios/externas/1.png";
import servicio2 from "../Images/servicios/externas/2.png";
import servicio3 from "../Images/servicios/externas/3.png";
import servicio4 from "../Images/servicios/externas/4.png";
import servicio5 from "../Images/servicios/externas/5.png";
import servicio6 from "../Images/servicios/externas/6.png";

const Servicios = () => {
    return (
        <Fragment>
            <div className="container contenedor_md center">
                <div className="container" style={{marginBottom:"50px"}}>
                    <p className="titulo_1 linea_3">Servicios</p>
                    <p>
                        Conoce todos los estudios en los que nos especializamos para conocer,
                        diagnosticar y proteger tu vista.
                    </p>
                </div>

                <div
                    className="contenedor_servicios"
                    style={{ paddingBottom: "150px" }}
                >
                    <div className="servicios_index_2 servicios_shadow">
                        <a href="/ultrasonido-ocular">
                            <img
                                src={servicio1}
                                alt="Ultrasonido ocular"
                                style={{ width: "100%" }}
                            />
                        </a>
                        <div>
                            <p className="margin_1 text_2">Ultrasonido ocular</p>
                            <a className="text_3" href="/ultrasonido-ocular">Ver más</a>
                        </div>
                    </div>
                    <div className="servicios_index_2 servicios_shadow">
                        <a href="/ultrabiomicroscopia">
                            <img
                                src={servicio2}
                                alt="Ultrabiomicroscopía"
                                style={{ width: "100%" }}
                            />
                        </a>
                        <div>
                            <p className="margin_1 text_2">Ultrabiomicroscopía</p>
                            <a className="text_3" href="/ultrabiomicroscopia">Ver más</a>
                        </div>
                    </div>
                    <div className="servicios_index_2 servicios_shadow">
                        <a href="/microscopia-especular">
                            <img
                                src={servicio3}
                                alt="Microscopía especular"
                                style={{ width: "100%" }}
                            />
                        </a>
                        <div>
                            <p className="margin_1 text_2">Microscopía especular</p>
                            <a className="text_3" href="/microscopia-especular">Ver más</a>
                        </div>
                    </div>
                    <div className="servicios_index_2 servicios_shadow">
                        <a href="/topografia-corneal">
                            <img
                                src={servicio4}
                                alt="Topografía Corneal"
                                style={{ width: "100%" }}
                            />
                        </a>
                        <div>
                            <p className="margin_1 text_2">OPD</p>
                            <a className="text_3" href="/topografia-corneal">Ver más</a>
                        </div>
                    </div>
                    <div className="servicios_index_2 servicios_shadow">
                        <a href="/calculo-de-lente-intraocular">
                            <img
                                src={servicio5}
                                alt="Cálculo de lente intraocular"
                                style={{ width: "100%" }}
                            />
                        </a>
                        <div>
                            <p className="margin_1 text_2">Cálculo de lente intraocular</p>
                            <a className="text_3" href="/calculo-de-lente-intraocular">Ver más</a>
                        </div>
                    </div>
                    <div className="servicios_index_2 servicios_shadow">
                        <a href="/paquimetria-ultrasonica">
                            <img
                                src={servicio6}
                                alt="Paquimetría ultrasónica"
                                style={{ width: "100%" }}
                            />
                        </a>
                        <div>
                            <p className="margin_1 text_2">Paquimetría ultrasónica</p>
                            <a className="text_3" href="/paquimetria-ultrasonica">Ver más</a>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Servicios;
