import React from "react";

const Aviso = () => {
  return (
    <section className="container">
        <p className="titulo_1 linea_3">AVISO DE PRIVACIDAD</p>
        <p>
            A los Usuarios (como se definen posteriormente), les informamos que el
            siguiente Aviso de Privacidad, les es aplicable por el simple uso o
            acceso a cualquiera de las páginas, aplicaciones web y móviles,
            softwares y, aplicaciones en general, que integran el Portal de
            http://imagenoftalmologica.com (en adelante y, conjunta e
            indistintamente, el “Portal”), por lo que entenderemos que lo acepta y
            acuerda en obligarse en su cumplimiento. En caso de que no esté de
            acuerdo con el Aviso de Privacidad y/o con los Términos y Condiciones a
            su disposición, deberá abstenerse de acceder o utilizar el Portal.
        </p>
        <p>
            El Usuario, entendido como aquella persona que realiza el uso o accede,
            mediante equipo de cómputo y/o cualquier equipo de comunicación o
            dispositivo, al Portal (en adelante el “Usuario”), acepta plena y sin
            reserva todas y cada una de las disposiciones incluidas en el presente
            Aviso de Privacidad.
        </p>
        <p>
            RESPONSABLE DEL TRATAMIENTO DE SUS DATOS PERSONALES. Para Imagen
            Oftalmológica (en adelante la “Empresa”) la seguridad de los Usuarios es
            nuestra prioridad, por lo que protegemos sus datos personales mediante
            el uso, aplicación y mantenimiento de altas medidas de seguridad
            técnicas, físicas y administrativas.
        </p>
        <p>
            Como Usuario, usted tiene la oportunidad de escoger entre una amplia
            gama de servicios a través de nuestro Portal, sabiendo que sus datos
            personales estarán protegidos y serán tratados de manera confidencial.
            Les informamos que el RESPONSABLE de recabar y dar tratamiento y/o
            utilizar los datos personales que el Usuario proporcione a través del
            Portal, es la Empresa, así como sus subsidiarias, asociadas, sociedades
            controladoras y afiliadas.
        </p>
        <p>
            DOMICILIO DEL RESPONSABLE. Para efectos del presente aviso de
            privacidad, la Empresa señala, individualmente, como su domicilio, el
            ubicado en Puente de Piedra 150, Toriello Guerra, Tlalpan, 14050 Ciudad
            de México, CDMX.
        </p>
        <p>
            DATOS PERSONALES QUE PUEDEN SER RECOLECTADOS. Los datos personales que
            la Empresa puede recopilar del Usuario al utilizar el Portal y/o
            contratar nuestros servicios, son los siguientes:
            <br />
            Nombre, teléfono y correo
            <br />
            Acceso desde redes sociales
            <br />
            Datos para sus estudios
            <br />
            Receta de su especialista
        </p>
        <p>
            FINALIDADES DEL TRATAMIENTO DE SUS DATOS PERSONALES. Los datos
            personales que la Empresa recabe serán utilizados para atender las
            siguientes finalidades:
            <br />
            Identificación de cliente y garantizar una atención personalizada
        </p>
        <p>
            Para integrar expedientes, bases de datos y sistemas necesarios para
            llevar a cabo las operaciones y servicios correspondientes; (ii) Para
            llevar a cabo análisis internos;
        </p>
        <p>
            De manera adicional, se podrán utilizar sus datos personales para las
            siguientes finalidades secundarias: (i) Mercadotecnia, publicidad y
            prospección comercial; (ii) Ofrecerle, en su caso, otros servicios
            propios de la Empresa o de cualquiera de sus afiliadas, subsidiarias,
            sociedades controladoras, asociadas, comisionistas o sociedades; (iii)
            Remitirle promociones de otros bienes y servicios; (iv) Para realizar
            análisis estadísticos, de generación de modelos de información y/o
            perfiles de comportamiento actual y predictivo y, (v) Para participar en
            encuestas, sorteos y promociones.
        </p>
        <p>
            OPCIONES Y MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE LOS DATOS. La
            Empresa tiene implementadas medidas de seguridad administrativas,
            técnicas y físicas para proteger sus datos personales, mismas que
            igualmente exigimos sean cumplidas por los proveedores de servicios que
            contratamos. Usted podrá limitar el uso o divulgación de sus datos
            personales enviando un correo electrónico a
            contacto@imagenoftalmologica.com indicándonos en el cuerpo del correo su
            nombre completo y que dato desea que no sea divulgado, de proceder su
            solicitud, se le registrará en el listado de exclusión.
        </p>
        <p>
            MEDIOS PARA EJERCER LOS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U
            OPOSICIÓN (DERECHOS ARCO). Puede enviar un correo electrónico a
            contacto@imagenoftalmologica.com, en cualquier momento, para ejercer sus
            Derechos de Acceso, Rectificación, Cancelación u Oposición (“Derechos
            ARCO”). Para ejercer los Derechos ARCO, usted (o su representante
            legal), deberá presentar la solicitud, identificándose con la siguiente
            documentación:
            <br />
            Nombre del usuario o titular.
            <br />
            Domicilio del usuario o titular u otro medio para comunicarle la
            respuesta a su solicitud.
            <br />
            Documentos que acrediten su identidad (IFE/INE o pasaporte) y, en su
            caso, los documentos que acrediten la representación legal del
            solicitante.
            <br />
            Una descripción de la información / los datos sobre los que está
            tratando de ejercer sus derechos ARCO y los derechos que le gustaría
            ejercer.
        </p>
        <p>
            Si uno o más de los documentos mencionados anteriormente no están
            incluidos, y/o los documentos tienen errores u omisiones, la Empresa le
            notificará dentro de los 3 días hábiles posteriores a la recepción de la
            solicitud y le pedirá los documentos faltantes y/o las correcciones
            pertinentes; tendrá 5 días hábiles a partir de esa notificación para
            proporcionar la información actualizada, de lo contrario, la solicitud
            se entenderá como no presentada.
        </p>
        <p>
            TRANSFERENCIA DE DATOS PERSONALES. La Empresa podrá divulgar sus datos
            personales a aquellos terceros que, en virtud de los servicios y
            productos ofrecidos, necesiten conocerlos para cumplir cabalmente con
            los mismos.
        </p>
        <p>
            Asimismo, la Empresa puede divulgar su información a las autoridades
            competentes en términos de la legislación aplicable; cualquier
            transferencia de sus datos personales sin consentimiento se realizará de
            acuerdo con el Artículo 37 de la LFPDPPP.
        </p>
        <p>
            WEB BEACONS. La Empresa, podrá o no, utilizar tecnologías de seguimiento
            tales como web beacons, similares a las cookies, para recabar datos
            sobre sus visitas en el Portal; éstas son pequeñas imágenes electrónicas
            incrustadas en el contenido web o mensajes de correo electrónico, las
            cuales no se encuentran normalmente visibles para los Usuarios y que nos
            permiten generar contenidos casi personalizados para ofrecerle una mejor
            experiencia cuando utilice nuestro Portal.
        </p>
        <p>
            En caso de no estar de acuerdo con cualquiera de las condiciones aquí
            establecidas, el Usuario siempre podrá cambiar la configuración de su
            navegador.
        </p>
        <p>
            MODIFICACIONES AL AVISO DE PRIVACIDAD. El presente aviso de privacidad
            puede sufrir modificaciones, cambios o actualizaciones derivadas, entre
            otras cosas, por nuevos requerimientos legales; necesidades propias de
            la Empresa, por los productos o servicios que ofrecemos; por nuestras
            prácticas de privacidad; por cambios en nuestro modelo de negocio, o por
            otras causas.
        </p>
        <p>
            Cualquier modificación al presente aviso de privacidad le será
            notificada a través de cualquiera de los siguientes medios: un
            comunicado por escrito enviado a su domicilio; por el correo electrónico
            que señale; un mensaje a su teléfono móvil; un mensaje dado a conocer a
            través del Portal o de cualquier medio electrónico que utilice para
            celebrar operaciones; o bien, en periódicos de amplia circulación el
            domicilio social de la Empresa.
        </p>
    </section>
  );
};

export default Aviso;
